<template>
  <v-theme-provider :dark="dark">
    <div>
      <base-info-card
        :title="title"
        color="primary"
      >
        <slot />
      </base-info-card>
      <template v-for="({ icon, text, title: t }, i) in business">
        <base-avatar-card
          :key="i"
          :icon="icon"
          :outlined="false"
          :title="!dense ? t : undefined"
          color="transparent"
          horizontal
          space="0"
        >
          <!-- Do not use v-html for user -->
          <!-- provided values -->
          <div v-html="text" />
        </base-avatar-card>

        <v-divider
          v-if="i + 1 !== business.length"
          :key="`divider-${i}`"
          class="my-2"
        />
      </template>
    </div>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'BaseBusinessContact',

    props: {
      dark: Boolean,
      dense: Boolean,
      title: String,
    },

    data: () => ({
      business: [
        {
          icon: 'mdi-map-marker-outline',
          title: 'Address',
          text: 'Unit 16, 150 Cavendish Road<br>Redwood, Christchurch 8051',
        },
        // {
        //   icon: 'mdi-cellphone',
        //   title: 'Phone',
        //   text: '+64 3 595 2175',
        // },
        {
          icon: 'mdi-email',
          title: 'Email',
          text: 'sales<i aria-hidden="true" class="v-icon notranslate mb-2 mdi mdi-at theme--light" style="padding-top: 5px; font-size: 16px; height: 10px;"></i>breezysoftware.com<br>accounts<i aria-hidden="true" class="v-icon notranslate mb-2 mdi mdi-at theme--light" style="padding-top: 5px; font-size: 16px; height: 10px;"></i>breezysoftware.com',
        },
      ],
    }),
  }
</script>
