<template>
  <div>
    <transition name="bounce">
      <div v-if="!messageSent" >
        <base-info-card
          :title="title"
          :subtitle="subtitle"
          space="4"
          color="primary"
        />

        <base-text-field
          v-model="name"
          label="Name"
        />

        <base-text-field
          v-model="email"
          label="Email"
        />

        <base-text-field
          v-model="subject"
          label="Subject"
        />

        <base-text-field
          v-model="other"
          label="other"
          style="display: none;"
        />

        <base-textarea
          v-model="text"
          class="mb-6"
          label="Your Need & Description"
        />
        
        <v-btn
          :color="!theme.isDark ? 'accent' : 'white'"
          outlined
          @click="sendEmail()"
          :disabled="!valid"
          tile
          large
          bold
        >
          Send message
        </v-btn>
      </div>
    </transition>
    <div v-if="showThankYou" style="padding-top: 42.5%; padding-bottom: 42.5%; text-align: center">
      <h3 text-uppercase title mb-1 style="color: #3a93dc;">MESSAGE SENT!</h3>
    </div>
  </div>
</template>

<script>
  import emailjs from 'emailjs-com'
  export default {
    name: 'BaseContactForm',

    // Injected from the Vuetify Themeable mixin
    inject: ['theme'],

    props: {
      subtitle: String,
      title: {
        type: String,
        default: 'MAIL US YOUR MESSAGE',
      },
    },

    data () {
      return {
        name: '',
        email: '',
        subject: '',
        text: '',
        messageSent: false,
        showThankYou: false,
        other: '',
      }
    },

    computed: {
      valid: function () {
        return this.name != '' &&  this.email != '' && this.subject != '' && this.text != '' && this.other == ''
      }
    },

    methods: {
      sendEmail () {
        try {
          emailjs.send('service_eg788oy', 'template_9etejm9', {
                         name: this.name,
                         email: this.email,
                         subject: this.subject,
                         message: this.text,
                       },
                       'user_mN0QRlsaEX3hGST5nlYqz')
        } catch (error) {
          console.log({ error })
        }
        // Reset form field
        this.name = ''
        this.email = ''
        this.subject = ''
        this.text = ''
        this.messageSent = true
        setTimeout(() => this.showThankYou = true, 550)
      },

    },
  }
</script>
<style>
.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
.transition-wrapper {
  height: 0;
  overflow: hidden;
  transition: height 0.66s ease-out;
}
</style>
